import React from 'react'
import { GiClapperboard } from 'react-icons/gi'

import { Template } from '../../../components/template'
import {
  Title,
  FormSection,
  DealersForm,
  ErrorMessage,
  CustomButton,
  BottomImagesContainer,
  BottomLogoImg,
  QuestionsContainer,
  DealersContainer
} from '../styles'
import { useForm, Controller } from 'react-hook-form'
import Select from '../../../components/Select'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { navigate } from 'gatsby'
import logoFilled from '../../../assets/images/global/ford-logo-filled.svg'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

const errorMessage = 'Campo obrigatório'

const schema = yup
  .object({
    dealer: yup.string().required(errorMessage),
    clientName: yup.string().required(errorMessage),
    vin: yup.string().required(errorMessage)
  })
  .required()

const ClientsHome = ({ pageContext }) => {
  const {
    website: {
      meta_title,
      meta_description,
      main_title_dealers_text,
      submain_title_dealers_text,
      select_dealer_text,
      dealer_client_input_text,
      dealer_chasis_input_text,
      dealer_question_text,
      dealer_button_go_to_client_screen
    }
  } = pageContext
  const breakpoints = useBreakpoint()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    const { clientName, dealer, vin } = data
    const code = dealer.split('-')[0].trim()
    navigate(`/clients?dealerCode=${code.toUpperCase()}&clientName=${clientName.toUpperCase()}&vin=${vin.toUpperCase()}`)
  }

  return (
    <Template title={meta_title} description={meta_description} language={'PT-br'} has_cookie_consent={false}>
      <DealersContainer>
        <Title>
          {main_title_dealers_text} <br /> {submain_title_dealers_text}
        </Title>

        <DealersForm onSubmit={handleSubmit(onSubmit)}>
          <FormSection
            style={{
              paddingBottom: errors.dealer ? '2rem' : 0
            }}
          >
            <p style={{ paddingTop: '16px' }}>{select_dealer_text}</p>
            <Controller
              render={({ field: { onChange, value }, fieldState: { invalid } }) => (
                <Select
                  showNoFeeback
                  textError={errorMessage}
                  showCustomError={invalid}
                  placeholder=" "
                  options={pageContext.website[pageContext.envs.DEALERS].map(({ name, code }) => {
                    const optionLabel = `${code} - ${name}`

                    return {
                      value: optionLabel,
                      label: optionLabel
                    }
                  })}
                  value={{ value }}
                  onChange={option => onChange(option.value)}
                />
              )}
              name="dealer"
              control={control}
            />
          </FormSection>
          <FormSection>
            <p>{dealer_client_input_text}</p>
            <div>
              <input {...register('clientName', { required: true })} style={{ textTransform: 'uppercase' }} />
              {errors.clientName && <ErrorMessage>{errors.clientName.message}</ErrorMessage>}
            </div>
          </FormSection>
          <FormSection>
            <p>{dealer_chasis_input_text}</p>
            <div>
              <input {...register('vin', { required: true })} style={{ textTransform: 'uppercase' }} />
              {errors.vin && <ErrorMessage>{errors.vin.message}</ErrorMessage>}
            </div>
          </FormSection>
          <FormSection>
            <QuestionsContainer>
              <p>{dealer_question_text}</p>
              <GiClapperboard size={breakpoints.sm ? 48 : 64} />
            </QuestionsContainer>
            <CustomButton type="submit">{dealer_button_go_to_client_screen}</CustomButton>
          </FormSection>
        </DealersForm>
        <BottomImagesContainer>
          <BottomLogoImg alt="ford logo filled" src={logoFilled} />
        </BottomImagesContainer>
      </DealersContainer>
    </Template>
  )
}

export default ClientsHome
